import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import { useFormik } from "formik";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { RootState, useAppDispatch } from "redux/store";
import { BuyerInfoFieldEnum, BuyerInfoRequirementType, ModalTypeEnum, SupportedLanguageEnum } from "utils/enums";
import { StringResources } from "utils/language/languageResource";
import "./createPaymentLinkModal.scss";
import Decimal from "decimal.js";
import BaseInput from "components/baseInput/baseInput";
import BaseSelect, { Option } from "components/baseSelect/baseSelect";
import BaseDatepicker from "components/baseDatepicker/baseDatepicker";
import BaseRadio from "components/baseRadio/baseRadio";
import BaseToggleSwitch, { IToggleOption } from "components/baseToggleSwitch/baseToggleSwitch";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { formatter } from "utils/formatter";
import PlussAddIcon from "components/icons/plusAddIcon";
import CancelInputIcon from "components/icons/cancelInputIcon";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    IBuyerInfoData,
    IEcomResponseData,
    IPaymentLinkThemeResponseData,
    IProductItemsData,
} from "./createPaymentLinkModel";
import BaseCurrencyInputATM from "components/baseCurrencyInput/baseCurrencyInputATM";

interface IProductRow {
    description?: string;
    quantity?: number;
    amountWithoutDiscount?: number;
    unitPrice?: number;
    amount?: number;
    id?: number;
}

interface IPaymentPageOptions {
    description: string;
    terminalId: number;
}

interface IPaymentThemeLinkOptions {
    description: string;
    themeId: number;
}

interface ILanguageOptions {
    description: string;
    languageId: SupportedLanguageEnum;
}

interface IPaymentLinkBuyerInfo {
    id?: number | undefined;
    label: string;
    buyerInfoRequirementType: BuyerInfoRequirementType;
    buyerInfoField: BuyerInfoFieldEnum;
}

interface ICreatePaymentLinkRequestForm {
    paymentPage?: number;
    paymentLinkTheme?: number;
    language?: SupportedLanguageEnum;
    payByLinkDescription?: string;
    purchaseSuccessFulText?: string;
    purchaseReturnUrl?: string;
    dateTo?: Date;
    salesType?: number;
    exactSalesCount?: number;
    productValues: IProductRow[];
    buyerInfoData: Array<IPaymentLinkBuyerInfo>;
}

export interface CreatePaymentLinkModalProps {
    title?: string;
    actionButtonText?: string;
    data: {
        isUpdate: boolean;
        isCopy: boolean;
        paymentLinkId?: number | undefined;
    };
}

const CreatePamentLinkModal = (props: CreatePaymentLinkModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [paymentPageOptions, setPaymentPageOptions] = useState<Array<Option>>([]);
    const [paymentPage, setPaymentPage] = useState<Array<IEcomResponseData>>([]);
    const [paymentLinkThemeOptions, setPaymentLinkThemeOptions] = useState<Array<Option>>([]);
    const [paymentLinkTheme, setPaymentLinkTheme] = useState<Array<IPaymentLinkThemeResponseData>>([]);
    const [languageOptions, setLanguageOptions] = useState<Array<Option>>([]);
    const merchantId = useSelector((state: RootState) => state.user.selectedMerchant?.id);
    const [totalAmount, setTotalAmount] = useState<Decimal>(new Decimal(0));
    const [isSubmittedWithoutDate, setIsSubmittedWithoutDate] = useState<string | undefined>(undefined);

    const [toggleOptions, setToggleOptions] = useState<Array<IToggleOption<number>>>([
        {
            label: t(StringResources.modal.createPaymentLink.hiddenLabel),
            value: 1,
        },
        {
            label: t(StringResources.modal.createPaymentLink.optionalabel),
            value: 2,
        },
        {
            label: t(StringResources.modal.createPaymentLink.requiredLabel),
            value: 3,
        },
    ]);

    useEffect(() => {
        const fetchSelectOptions = async () => {
            try {
                const response = await executeAxiosRequestWithRefresh({
                    url: `/api/payByLink/get-pay-by-link-request-data`,
                    method: "GET",
                    params: { merchantId },
                });

                if (response && response.data) {
                    const ecomResponseData = response.data.ecomResponseData;
                    const paymentLinkThemeResponseData = response.data.paymentLinkThemeResponseData;

                    setPaymentPage(ecomResponseData);

                    const paymentPageOptions: Array<Option> = ecomResponseData.map((x: IEcomResponseData) => {
                        return {
                            label: `${x.merchantName} - ${x.contractNumber} - ${x.currencyId} - ${x.tid}`,
                            value: x.terminalId,
                            selected: false,
                        };
                    });

                    setPaymentPageOptions(paymentPageOptions);

                    setPaymentLinkTheme(paymentLinkThemeResponseData);

                    const paymentLinkThemeOptions: Array<Option> = paymentLinkThemeResponseData.map(
                        (x: IPaymentLinkThemeResponseData) => {
                            return {
                                label: `${x.themeId} - ${x.themeName} - ${x.themeDescription}`,
                                value: x.themeId,
                                selected: false,
                            };
                        }
                    );

                    setPaymentLinkThemeOptions(paymentLinkThemeOptions);
                }
            } catch (error) {
                toast.error("Some error");
            }
        };

        const fetchPaymentLinkDetailsData = async () => {
            try {
                const paymentLinkId = props.data.paymentLinkId;
                const response = await executeAxiosRequestWithRefresh({
                    url: `/api/payByLink/details`,
                    method: "GET",
                    params: { paymentLinkId },
                });

                if (response && response.data) {
                    formValidation.setFieldValue("paymentPage", response.data.terminalId);
                    formValidation.setFieldValue("paymentLinkTheme", response.data.paymentLinkThemeId);
                    formValidation.setFieldValue("payByLinkDescription", response.data.paymentLinkDescription);
                    formValidation.setFieldValue("purchaseSuccessFulText", response.data.purchaseSuccessFulText);
                    formValidation.setFieldValue("purchaseReturnUrl", response.data.purchaseReturnUrl);
                    formValidation.setFieldValue("language", response.data.languageType);

                    const productResponseData = response.data.productItemsData;
                    const buyerInfoResponseData = response.data.buyerInfoData;

                    if (props.data.isCopy) {
                        formValidation.setFieldValue("dateTo", undefined);
                    } else {
                        formValidation.setFieldValue("dateTo", response.data.validTo);
                    }

                    const productItems: Array<IProductRow> = productResponseData.map((x: IProductItemsData) => {
                        return {
                            id: x.id,
                            description: x.description,
                            quantity: x.quantity,
                            amountWithoutDiscount: x.amountWithoutDiscount,
                            amount: x.amount,
                            unitPrice: x.unitPrice,
                        };
                    });

                    formValidation.setFieldValue("productValues", productItems);

                    const buyerInfoData: Array<IPaymentLinkBuyerInfo> = buyerInfoResponseData.map(
                        (x: IBuyerInfoData) => {
                            return {
                                id: x.id,
                                buyerInfoRequirementType: x.buyerInfoRequirementType,
                                BuyerInfoFieldEnum: x.buyerInfoField,
                            };
                        }
                    );

                    formValidation.setFieldValue("buyerInfoData", buyerInfoData);
                }
            } catch (error) {
                toast.error("Some error");
            }
        };

        const fetchOptions = () => {
            const paymentPageValues: Array<IPaymentPageOptions> = [
                {
                    description: "Soprahnoss - 358836 - ISK - 20009557",
                    terminalId: 1,
                },
                {
                    description: "Yisk - 338971 - ISK - 20001557",
                    terminalId: 2,
                },
            ];

            const paymentPageOptions: Array<Option> = paymentPageValues.map((x: IPaymentPageOptions) => {
                return {
                    label: `${x.description}`,
                    value: x.terminalId,
                    selected: false,
                };
            });

            setPaymentPageOptions(paymentPageOptions);

            const paymentLinkThemeValues: Array<IPaymentThemeLinkOptions> = [
                {
                    description: "525879-01 - Kronan - Kronan theme (default)",
                    themeId: 1,
                },
                {
                    description: "525879-02 – Kronan2 - Kronan secondary theme",
                    themeId: 2,
                },
            ];

            const paymentLinkThemeOptions: Array<Option> = paymentLinkThemeValues.map((x: IPaymentThemeLinkOptions) => {
                return {
                    label: `${x.description}`,
                    value: x.themeId,
                    selected: false,
                };
            });

            setPaymentLinkThemeOptions(paymentLinkThemeOptions);

            const languageValues: Array<ILanguageOptions> = [
                {
                    description: "English",
                    languageId: SupportedLanguageEnum.English,
                },
                {
                    description: "Icelandic",
                    languageId: SupportedLanguageEnum.Icelandic,
                },
            ];

            const languageOptions: Array<Option> = languageValues.map((x: ILanguageOptions) => {
                return {
                    label: `${x.description}`,
                    value: x.languageId,
                    selected: false,
                };
            });

            setLanguageOptions(languageOptions);
        };

        if (props.data.isUpdate || props.data.isCopy) {
            fetchPaymentLinkDetailsData();
        }

        // todo change after be is merged
        fetchOptions();
        fetchSelectOptions();
    }, []);

    const formValidation = useFormik({
        enableReinitialize: false,
        validateOnChange: true,
        validateOnMount: true,
        validateOnBlur: true,
        initialValues: {
            paymentPage: undefined,
            paymentLinkTheme: undefined,
            payByLinkDescription: "",
            purchaseSuccessFulText: "",
            purchaseReturnUrl: "",
            dateTo: undefined,
            salesType: undefined,
            exactSalesCount: undefined,
            buyerInfoData: [
                {
                    label: `${t(StringResources.modal.createPaymentLink.ssn)}`,
                    buyerInfoField: BuyerInfoFieldEnum.Ssn,
                    buyerInfoRequirementType: BuyerInfoRequirementType.Optional,
                },
                {
                    label: `${t(StringResources.modal.createPaymentLink.fullName)}`,
                    buyerInfoField: BuyerInfoFieldEnum.FullName,
                    buyerInfoRequirementType: BuyerInfoRequirementType.Optional,
                },
                {
                    label: `${t(StringResources.modal.createPaymentLink.address)}`,
                    buyerInfoField: BuyerInfoFieldEnum.Address,
                    buyerInfoRequirementType: BuyerInfoRequirementType.Optional,
                },
                {
                    label: `${t(StringResources.modal.createPaymentLink.postCode)}`,
                    buyerInfoField: BuyerInfoFieldEnum.PostCode,
                    buyerInfoRequirementType: BuyerInfoRequirementType.Optional,
                },
                {
                    label: `${t(StringResources.modal.createPaymentLink.city)}`,
                    buyerInfoField: BuyerInfoFieldEnum.City,
                    buyerInfoRequirementType: BuyerInfoRequirementType.Optional,
                },
                {
                    label: `${t(StringResources.modal.createPaymentLink.country)}`,
                    buyerInfoField: BuyerInfoFieldEnum.Country,
                    buyerInfoRequirementType: BuyerInfoRequirementType.Optional,
                },
                {
                    label: `${t(StringResources.modal.createPaymentLink.telephone)}`,
                    buyerInfoField: BuyerInfoFieldEnum.Telephone,
                    buyerInfoRequirementType: BuyerInfoRequirementType.Optional,
                },
                {
                    label: `${t(StringResources.modal.createPaymentLink.emailAddress)}`,
                    buyerInfoField: BuyerInfoFieldEnum.EmailAddress,
                    buyerInfoRequirementType: BuyerInfoRequirementType.Optional,
                },
                {
                    label: `${t(StringResources.modal.createPaymentLink.comments)}`,
                    buyerInfoField: BuyerInfoFieldEnum.Comments,
                    buyerInfoRequirementType: BuyerInfoRequirementType.Optional,
                },
            ],
            productValues: [
                {
                    description: undefined,
                    quantity: 1,
                    amountWithoutDiscount: 0,
                    unitPrice: 0,
                    amount: 0,
                },
            ],
        },
        validationSchema: Yup.object({
            paymentPage: Yup.number().required(t(StringResources.modal.createPaymentLink.paymentPageRequired)),
            paymentLinkTheme: Yup.number().required(
                t(StringResources.modal.createPaymentLink.paymentLinkThemeRequired)
            ),
            purchaseReturnUrl: Yup.string().required(
                t(StringResources.modal.createPaymentLink.purchaseReturnUrlRequired)
            ),
            language: Yup.number().required(t(StringResources.modal.createPaymentLink.languageRequired)),
            productValues: Yup.array().of(
                Yup.object().shape({
                    description: Yup.string().required(
                        `${t(StringResources.modal.createPaymentLink.descriptionRequired)}`
                    ),
                    quantity: Yup.number()
                        .min(1, `${t(StringResources.modal.createPaymentLink.quantityMustBeAtLeastOne)}`)
                        .required(`${t(StringResources.modal.createPaymentLink.quantityIsRequired)}`),
                    unitPrice: Yup.number()
                        .test("is-greater-than-zero", function (value) {
                            if (value! <= 0) {
                                return false;
                            } else {
                                return true;
                            }
                        })
                        .required(),
                    amountWithoutDiscount: Yup.number()
                        .test("is-greater-than-zero", function (value) {
                            if (value! <= 0) {
                                return false;
                            } else {
                                return true;
                            }
                        })
                        .required(),
                    amount: Yup.number()
                        .test(
                            "is-lower-or-equal",
                            `${t(StringResources.modal.createPaymentLink.quantityIsRequired)}`,
                            function (value) {
                                const amountWithoutDiscount = this.parent.amountWithoutDiscount;
                                if (value! <= amountWithoutDiscount) {
                                    return true;
                                } else return false;
                            }
                        )
                        .required(),
                })
            ),
            salesType: Yup.number().required("Sales type required"),
            exactSalesCount: Yup.number().when("salesType", {
                is: 3,
                then: Yup.number().required(t(StringResources.modal.createPaymentLink.exactSalesCountRequired)),
                otherwise: Yup.number().notRequired(),
            }),
        }),
        onSubmit: async (value: ICreatePaymentLinkRequestForm) => {
            const totalAmount = prodValues
                .filter(x => x.amount != 0 && x.amount != undefined)
                .map(x => x.amount!)
                .reduce((a, b) => a.plus(b), new Decimal(0))
                .toNumber();

            // don't send if amount is 0 for the last
            let prodValuesForRequest = prodValues.map(item => ({
                ...item,
                amount: item.amount,
                unitPrice: item.unitPrice,
                amountWithoutDiscount: item.amountWithoutDiscount,
            }));

            prodValuesForRequest = prodValuesForRequest.filter(x => x.amount != 0);

            // don't send ones that are hidden because we don't save them to db
            let buyerInfoDataForRequest = formValidation.values.buyerInfoData
                .filter(x => x.buyerInfoRequirementType != BuyerInfoRequirementType.Hidden)
                .map(({ label, ...item }) => item);

            if (value.dateTo === undefined && isSubmittedWithoutDate === undefined) {
                setIsSubmittedWithoutDate(`${t(StringResources.modal.createPaymentLink.dateToEmptyInfoMessage)}`);
                return;
            }

            if (!props.data.isUpdate || props.data.isCopy) {
                // remove ids from request
                prodValuesForRequest = prodValuesForRequest.map(({ id, ...item }) => item);
                buyerInfoDataForRequest = buyerInfoDataForRequest.map(({ id, ...item }) => item);
                try {
                    await executeAxiosRequestWithRefresh({
                        url: `/api/payByLink/create-payment-link`,
                        method: "POST",
                        data: {
                            terminalId: value.paymentPage,
                            paymentLinkThemeId: value.paymentLinkTheme,
                            payByLinkDescription: value.payByLinkDescription,
                            purchaseSuccessfulText: value.purchaseSuccessFulText,
                            languageType: value.language,
                            purchaseReturnUrl: value.purchaseReturnUrl,
                            validTo: value.dateTo,
                            buyerInfos: buyerInfoDataForRequest,
                            salesType: value.salesType,
                            exactSalesCount: value.exactSalesCount,
                            productDetails: prodValuesForRequest,
                            totalAmount: totalAmount,
                        },
                    });
                    dispatch(confirmModal({ modalType: ModalTypeEnum.CreatePaymentLink }));
                    return;
                } catch (error: any) {
                    toast.error(t(StringResources.pages.terminals.error.submitError).toString());
                }
            } else {
                try {
                    await executeAxiosRequestWithRefresh({
                        url: `/api/payByLink/update-payment-link`,
                        method: "POST",
                        data: {
                            paymentLinkId: props.data.paymentLinkId!,
                            terminalId: value.paymentPage,
                            paymentLinkThemeId: value.paymentLinkTheme,
                            payByLinkDescription: value.payByLinkDescription,
                            purchaseSuccessfulText: value.purchaseSuccessFulText,
                            languageType: value.language,
                            purchaseReturnUrl: value.purchaseReturnUrl,
                            validTo: value.dateTo,
                            buyerInfos: buyerInfoDataForRequest,
                            salesType: value.salesType,
                            exactSalesCount: value.exactSalesCount,
                            productDetails: prodValuesForRequest,
                            totalAmount: totalAmount,
                        },
                    });
                    dispatch(confirmModal({ modalType: ModalTypeEnum.CreatePaymentLink }));
                    return;
                } catch (error: any) {
                    toast.error(t(StringResources.pages.terminals.error.submitError).toString());
                }
            }
        },
    });

    const [prodValues, setProdValues] = useState(formValidation.values.productValues);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.CreatePaymentLink }));
    };

    const onOkClick = async () => {
        await formValidation.handleSubmit();
    };

    const onPaymentPageChange = async (selectedItem: Option | undefined) => {
        if (selectedItem && selectedItem.value !== 0) {
            await formValidation.setFieldValue("paymentPage", selectedItem.value);
        } else {
            await formValidation.setFieldValue("paymentPage", undefined);
        }
    };

    const onPaymentLinkThemeChange = async (selectedItem: Option | undefined) => {
        if (selectedItem && selectedItem.value !== 0) {
            await formValidation.setFieldValue("paymentLinkTheme", selectedItem.value);
        } else {
            await formValidation.setFieldValue("paymentLinkTheme", undefined);
        }
    };

    const onLanguageChange = (selectedItem: Option | undefined) => {
        if (selectedItem && selectedItem.value !== 0) {
            formValidation.setFieldValue("language", selectedItem.value);
        } else {
            formValidation.setFieldValue("language", undefined);
        }
    };

    const onDatePickerChange = (selectedDate: Date | undefined) => {
        if (selectedDate) {
            formValidation.setFieldValue("dateTo", selectedDate.toISOString());
        } else {
            formValidation.setFieldValue("dateTo", undefined);
        }
    };

    const onBaseRadioChange = async (selectedRadio: number) => {
        await formValidation.setFieldValue("salesType", selectedRadio);
        await formValidation.setFieldValue("exactSalesCount", undefined);
    };

    const updateproductsValue = (quantity: number, unitPrice: number, index: number) => {
        let productAmount = (quantity ?? 0) * unitPrice;
        const values = prodValues;
        values[index].amount = productAmount == 0 ? 0.001 : productAmount;
        values[index].amountWithoutDiscount = productAmount == 0 ? 0.001 : productAmount;
        setProdValues(values);
        formValidation.setFieldValue("productValues", values);
    };

    const onProductQuantityChange = async (event: any, index: number) => {
        const quantity = event.target.value;
        const values = prodValues;
        values[index].quantity = quantity;
        setProdValues(values);
        formValidation.setFieldValue("productValues", values);
        const unitPrice = formValidation.values["productValues"][index].unitPrice ?? 0;
        updateproductsValue(quantity, unitPrice, index);
    };

    const onUnitPriceChange = async (value: number, index: number) => {
        const values = prodValues;
        values[index].unitPrice = value;
        setProdValues(values);
        formValidation.setFieldValue("productValues", values);
        const quantity = formValidation.values["productValues"][index].quantity ?? 0;
        updateproductsValue(quantity, value, index);
    };

    const onAmountChange = async (value: number, index: number) => {
        const amount = value;
        const values = prodValues;
        values[index].amount = amount;
        setProdValues(values);
        formValidation.setFieldValue("productValues", values);
    };

    const getTodayDate = () => {
        const today = new Date();

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    };

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal" size="lg">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="create-payment-link__modal-body">
                    <div className="create-payment-link__settings-buyer-info">
                        <div className="create-payment-link__settings">
                            <div className="create-payment-link__subtitle">{`${t(
                                StringResources.modal.createPaymentLink.settingsTitle
                            )}`}</div>
                            <div className="create-payment-link__settings-input">
                                <BaseSelect
                                    label={`${t(StringResources.modal.createPaymentLink.paymentPageOptionsLabel)}`}
                                    options={paymentPageOptions.map(x => {
                                        return { ...x, selected: x.value == formValidation.values["paymentPage"] };
                                    })}
                                    onChange={option => onPaymentPageChange(option)}
                                    isClearable={true}
                                    invalid={
                                        formValidation.errors["paymentPage"] && formValidation.touched["paymentPage"]
                                            ? formValidation.errors["paymentPage"]
                                            : false
                                    }
                                    onBlur={e => {
                                        formValidation.setTouched({ paymentPage: true });
                                        formValidation.handleBlur(e);
                                    }}
                                ></BaseSelect>
                            </div>
                            <div className="create-payment-link__settings-input">
                                <BaseSelect
                                    label={`${t(StringResources.modal.createPaymentLink.paymentLinkThemeOptionsLabel)}`}
                                    options={paymentLinkThemeOptions.map(x => {
                                        return { ...x, selected: x.value == formValidation.values["paymentLinkTheme"] };
                                    })}
                                    onChange={option => onPaymentLinkThemeChange(option)}
                                    isClearable={true}
                                    invalid={
                                        formValidation.errors["paymentLinkTheme"] &&
                                        formValidation.touched["paymentLinkTheme"]
                                            ? formValidation.errors["paymentLinkTheme"]
                                            : false
                                    }
                                    onBlur={e => {
                                        formValidation.setTouched({ paymentLinkTheme: true });
                                        formValidation.handleBlur(e);
                                    }}
                                ></BaseSelect>
                            </div>
                            <div className="create-payment-link__settings-input">
                                <BaseInput
                                    type={"text"}
                                    name="payByLinkDescription"
                                    value={formValidation.values["payByLinkDescription"]}
                                    label={`${t(StringResources.modal.createPaymentLink.payByLinkDescription)}`}
                                    onBlur={formValidation.handleBlur}
                                    onChange={formValidation.handleChange}
                                ></BaseInput>
                            </div>
                            <div className="create-payment-link__settings-input">
                                <BaseInput
                                    type={"text"}
                                    name="purchaseSuccessFulText"
                                    value={formValidation.values["purchaseSuccessFulText"]}
                                    label={`${t(StringResources.modal.createPaymentLink.purchaseSuccessfulText)}`}
                                    onBlur={formValidation.handleBlur}
                                    onChange={formValidation.handleChange}
                                ></BaseInput>
                            </div>
                            <div className="create-payment-link__settings-input">
                                <BaseInput
                                    type="text"
                                    name="purchaseReturnUrl"
                                    invalid={
                                        formValidation.touched["purchaseReturnUrl"] &&
                                        formValidation.errors["purchaseReturnUrl"]
                                    }
                                    value={formValidation.values["purchaseReturnUrl"]}
                                    label={`${t(StringResources.modal.createPaymentLink.purchaseReturnUrl)}`}
                                    onBlur={formValidation.handleBlur}
                                    onChange={formValidation.handleChange}
                                ></BaseInput>
                            </div>
                            <div className="create-payment-link__settings-input">
                                <BaseSelect
                                    label={`${t(StringResources.modal.createPaymentLink.languageOptionsLabel)}`}
                                    options={languageOptions.map(x => {
                                        return { ...x, selected: x.value == formValidation.values["language"] };
                                    })}
                                    onChange={onLanguageChange}
                                    onBlur={e => {
                                        formValidation.setTouched({ language: true });
                                        formValidation.handleBlur(e);
                                    }}
                                    invalid={
                                        formValidation.errors["language"] && formValidation.touched["language"]
                                            ? formValidation.errors["language"]
                                            : false
                                    }
                                    isClearable={true}
                                ></BaseSelect>
                            </div>
                            {isSubmittedWithoutDate ? (
                                <div className="create-payment-link__settings-input">
                                    <BaseDatepicker
                                        info={isSubmittedWithoutDate}
                                        label={`${t(StringResources.modal.createPaymentLink.validTo)}`}
                                        onDateChange={onDatePickerChange}
                                        isClearable={true}
                                        value={formValidation.values["dateTo"]}
                                        minValue={getTodayDate()}
                                    ></BaseDatepicker>
                                </div>
                            ) : (
                                <div className="create-payment-link__settings-input">
                                    <BaseDatepicker
                                        label={`${t(StringResources.modal.createPaymentLink.validTo)}`}
                                        onDateChange={onDatePickerChange}
                                        isClearable={true}
                                        value={formValidation.values["dateTo"]}
                                        minValue={getTodayDate()}
                                    ></BaseDatepicker>
                                </div>
                            )}
                        </div>

                        <div>
                            <div className="create-payment-link__subtitle">{`${t(
                                StringResources.modal.createPaymentLink.buyerInfoTitle
                            )}`}</div>
                            {formValidation.values.buyerInfoData.map((item, index) => (
                                <div className="create-payment-link__buyer-info-options">
                                    <div className="create-payment-link__buyer-info-options-label">{item.label}</div>

                                    <div className="create-payment-link__buyer-info-options-option">
                                        <BaseToggleSwitch
                                            options={toggleOptions}
                                            activeValue={item.buyerInfoRequirementType}
                                            onChange={option =>
                                                formValidation.setFieldValue(
                                                    `buyerInfoData[${index}].buyerInfoRequirementType`,
                                                    option.value
                                                )
                                            }
                                        ></BaseToggleSwitch>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="create-payment-link__subtitle">
                        {`${t(StringResources.modal.createPaymentLink.salesTitle)}`}
                    </div>
                    <div className="create-payment-link__sales">
                        <div>
                            <BaseRadio
                                label={`${t(StringResources.modal.createPaymentLink.singleSales)}`}
                                name={"radio-1"}
                                checked={formValidation.values["salesType"] == 1}
                                value={1}
                                className={"create-payment-link__sales--radio-group"}
                                onChange={() => onBaseRadioChange(1)}
                            ></BaseRadio>
                        </div>
                        <div>
                            <BaseRadio
                                label={`${t(StringResources.modal.createPaymentLink.unlimitedSales)}`}
                                name={"radio-2"}
                                checked={formValidation.values["salesType"] == 2}
                                value={2}
                                className={"create-payment-link__sales--radio-group"}
                                onChange={() => onBaseRadioChange(2)}
                            ></BaseRadio>
                        </div>
                        <div>
                            <BaseRadio
                                label={`${t(StringResources.modal.createPaymentLink.exactSales)}`}
                                name={"radio-3"}
                                checked={formValidation.values["salesType"] == 3}
                                value={3}
                                className={"create-payment-link__sales--radio-group"}
                                onChange={() => onBaseRadioChange(3)}
                            ></BaseRadio>
                        </div>
                        <div className="create-payment-link__sales--item">
                            <BaseInput
                                label={`${t(StringResources.modal.createPaymentLink.exactSalesCount)}`}
                                type={"number"}
                                name="exactSalesCount"
                                value={formValidation.values["exactSalesCount"]}
                                onBlur={formValidation.handleBlur}
                                onChange={formValidation.handleChange}
                                disabled={formValidation.values["salesType"] !== 3}
                            ></BaseInput>
                        </div>
                    </div>

                    <div className="create-payment-link__subtitle">{`${t(
                        StringResources.modal.createPaymentLink.productsTitle
                    )}`}</div>
                    <div className="create-payment-link__products-header">
                        <div className="create-payment-link__products-header-title">
                            {`${t(StringResources.modal.createPaymentLink.productsDescription)}`}
                        </div>
                        <div className="create-payment-link__products-header-title">
                            {`${t(StringResources.modal.createPaymentLink.productsQuantity)}`}
                        </div>
                        <div className="create-payment-link__products-header-title">
                            {`${t(StringResources.modal.createPaymentLink.productsUnitPrice)}`}
                        </div>
                        <div className="create-payment-link__products-header-title">
                            {`${t(StringResources.modal.createPaymentLink.productAmountWithoutDiscount)}`}
                        </div>
                        <div className="create-payment-link__products-header-title">
                            {`${t(StringResources.modal.createPaymentLink.productsAmount)}`}
                        </div>
                    </div>
                    {prodValues.slice(0, -1).map((prodValue, index) => (
                        <ProductsRow
                            isLast={false}
                            description={prodValue.description}
                            quantity={prodValue.quantity}
                            amountWithoutDiscount={prodValue.amountWithoutDiscount}
                            unitPrice={prodValue.unitPrice}
                            amount={prodValue.amount}
                            onDeleteRow={() => {
                                setProdValues(values => values.filter((_, i) => i !== index));
                                return;
                            }}
                            onChange={(propName, event) => {
                                const values = prodValues;

                                if (propName === "description") {
                                    values[index].description = event.target.value;
                                    setProdValues(values);
                                    formValidation.setFieldValue("productValues", values);
                                } else if (propName === "quantity") {
                                    onProductQuantityChange(event, index);
                                }
                            }}
                            onNumberValueChange={(propName, value) => {
                                if (propName === "unitPrice") {
                                    onUnitPriceChange(value, index);
                                } else if (propName === "amount") {
                                    onAmountChange(value, index);
                                }
                            }}
                            onBlur={() => {
                                formValidation.handleBlur;
                            }}
                            checkInvalid={(propname: keyof IProductRow, value: string | number | undefined) => {
                                if (
                                    formValidation.errors["productValues"] === undefined ||
                                    formValidation.errors["productValues"]![index] === undefined
                                ) {
                                    return false;
                                }

                                return (
                                    // @ts-ignore
                                    formValidation.errors["productValues"]![index][`${propname}`]
                                );
                            }}
                        ></ProductsRow>
                    ))}
                    <ProductsRow
                        isLast={true}
                        description={prodValues[prodValues.length - 1].description}
                        quantity={prodValues[prodValues.length - 1].quantity}
                        amountWithoutDiscount={prodValues[prodValues.length - 1].amountWithoutDiscount}
                        unitPrice={prodValues[prodValues.length - 1].unitPrice}
                        amount={prodValues[prodValues.length - 1].amount}
                        onChange={(propName, event) => {
                            const values = prodValues;
                            const index = values.length - 1;
                            if (propName === "description") {
                                values[index].description = event.target.value;
                                setProdValues(values);
                                formValidation.setFieldValue("productValues", values);
                            } else if (propName === "quantity") {
                                onProductQuantityChange(event, index);
                            }
                        }}
                        onNumberValueChange={(propName, value) => {
                            const values = prodValues;
                            const index = values.length - 1;
                            if (propName === "unitPrice") {
                                onUnitPriceChange(value, index);
                            } else if (propName === "amount") {
                                onAmountChange(value, index);
                            }
                        }}
                        checkInvalid={(propname: keyof IProductRow, value: string | number | undefined) => {
                            const values = prodValues;
                            const index = values.length - 1;
                            if (
                                formValidation.errors["productValues"] === undefined ||
                                formValidation.errors["productValues"]![index] === undefined
                            ) {
                                return false;
                            }

                            return (
                                // @ts-ignore
                                formValidation.errors["productValues"]![index][`${propname}`]
                            );
                        }}
                        onAddRow={() => {
                            const values = prodValues;
                            values.push({
                                description: "-",
                                quantity: undefined,
                                amountWithoutDiscount: 0.001,
                                unitPrice: 0.001,
                                amount: 0.001,
                            });
                            setProdValues(values);
                            formValidation.setFieldValue("productValues", values);
                        }}
                        onBlur={() => {
                            formValidation.handleBlur;
                        }}
                    ></ProductsRow>
                    <div className="create-payment-link__total">
                        {`${t(StringResources.modal.createPaymentLink.totalAmount)}`} :{" "}
                        {formatter(
                            prodValues
                                .filter(x => x.amount != undefined)
                                .map(x => new Decimal(Number(x.amount!)))
                                .reduce((a, b) => a.plus(b), new Decimal(0))
                                .toNumber(),
                            "ISK"
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.modal.cancel)}`}
                        styleType="line"
                    />

                    <BaseButton
                        handleClick={onOkClick}
                        text={props.actionButtonText}
                        styleType="solid"
                        disabled={!(formValidation.isValid && !formValidation.isValidating)}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

interface IProductsRowProps extends IProductRow {
    isLast: boolean;
    onDeleteRow?: () => void;
    onAddRow?: () => void;
    onChange: (propName: keyof IProductRow, e: React.ChangeEvent<HTMLInputElement>) => void;
    onNumberValueChange: (propName: keyof IProductRow, value: number) => void;
    onBlur: () => void;
    checkInvalid: (propName: keyof IProductRow, value: string | number | undefined) => string | boolean | undefined;
}

const ProductsRow = (props: IProductsRowProps) => {
    return (
        <div className="create-payment-link__products-row">
            <div className="create-payment-link__products-row-input">
                <BaseInput
                    type="text"
                    name="productValues.description"
                    value={props.description}
                    onBlur={props.onBlur}
                    onChange={e => props.onChange("description", e)}
                />
            </div>
            <div className="create-payment-link__products-row-input">
                <BaseInput
                    type="number"
                    name="productValues.quantity"
                    value={props.quantity}
                    onBlur={props.onBlur}
                    onChange={e => props.onChange("quantity", e)}
                />
            </div>
            <div className="create-payment-link__products-row-input">
                <BaseCurrencyInputATM
                    currency="ISK"
                    hideCurrency={true}
                    hideSymbol={true}
                    name="productValues.unitPrice"
                    value={props.unitPrice}
                    onValueChange={e => props.onNumberValueChange("unitPrice", e)}
                    onBlur={props.onBlur}
                />
            </div>
            <div className="create-payment-link__products-row-input">
                <BaseCurrencyInputATM
                    currency="ISK"
                    hideCurrency={true}
                    hideSymbol={true}
                    name="productValues.amountWithoutDiscount"
                    value={props.amountWithoutDiscount}
                    onValueChange={() => {
                        return;
                    }}
                    disabled={true}
                />
            </div>
            <div className="create-payment-link__products-row-input">
                <BaseCurrencyInputATM
                    currency="ISK"
                    hideCurrency={true}
                    hideSymbol={true}
                    name="productValues.amount"
                    value={props.amount}
                    onValueChange={e => props.onNumberValueChange("amount", e)}
                    onBlur={props.onBlur}
                />
            </div>
            {!props.isLast ? (
                <div
                    className="create-payment-link__products-row-input--small create-payment-link__products-row-input--clickable"
                    onClick={props.onDeleteRow}
                >
                    <CancelInputIcon />
                </div>
            ) : (
                <div
                    className="create-payment-link__products-row-input--small create-payment-link__products-row-input--clickable"
                    onClick={props.onAddRow}
                >
                    <PlussAddIcon />
                </div>
            )}
        </div>
    );
};

export default CreatePamentLinkModal;

import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import BaseInput from "components/baseInput/baseInput";
import ColorPicker from "components/colorPicker/colorPicker";
import { CopyToClipboardIcon } from "components/icons";
import LogoUploader from "components/logoUploader/logoUploader";
import { useFormik } from "formik";
import { IGetEcomThemesResponseListData } from "pages/ecomThemes/api/ecomThemesModels";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { RootState, useAppDispatch } from "redux/store";
import { ModalTypeEnum } from "utils/enums";
import { getContentDispositionFilenameUtf8, getFile } from "utils/helperFunctions";
import { StringResources } from "utils/language/languageResource";
import * as Yup from "yup";
import "./addNewEcomThemeModal.scss";

interface IAddNewEcomThemeForm {
    themeId: string;
    name: string;
    description: string;
    colorHex: string;
    logo: File | null;
    merchantId?: string;
}

interface IAddEcomThemesModalProps {
    data: { ecomThemesCount: number; data?: IGetEcomThemesResponseListData };
}

const AddNewEcomThemeModal = (props: IAddEcomThemesModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [fetchedLogo, setLogo] = useState<File | null>(null);
    const activeMerchant = useSelector((state: RootState) => state.user.activeMerchant);
    const ecomTheme = props.data;

    if (ecomTheme) {
        useEffect(() => {
            const fetchEcomThemeLogo = async () => {
                try {
                    const response = await executeAxiosRequestWithRefresh({
                        url: `/api/ecom/fetch`,
                        responseType: "blob",
                        method: "GET",
                        params: {
                            EcomThemeLogoId: ecomTheme.data?.id,
                        },
                    });

                    const filename = getContentDispositionFilenameUtf8(response.headers);
                    const logoFile = getFile(response.data, filename, response.contentType!);
                    setLogo(logoFile);
                } catch (error: any) {
                    toast.error(error.message);
                }
            };

            fetchEcomThemeLogo();
        }, []);
    }

    const formValidation = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: true,
        initialValues: {
            themeId: !ecomTheme.data
                ? activeMerchant?.number +
                  "-" +
                  (props.data?.ecomThemesCount !== undefined && props.data?.ecomThemesCount == 0
                      ? "1"
                      : props.data?.ecomThemesCount ?? ""
                  ).toString()
                : ecomTheme.data.themeId,
            name: ecomTheme?.data?.name ?? "",
            description: ecomTheme?.data?.description ?? "",
            colorHex: ecomTheme?.data?.colorHex ?? "",
            logo: fetchedLogo ?? null,
            merchantId: activeMerchant?.id,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t(StringResources.pages.ecomThemes.error.nameIsRequired)),
            description: Yup.string().optional(),
            colorHex: Yup.string().required(t(StringResources.pages.ecomThemes.error.colorIsRequired)),
            logo: Yup.mixed().optional(),
        }),
        onSubmit: async (value: IAddNewEcomThemeForm) => {
            try {
                if (props.data.data) {
                    await executeAxiosRequestWithRefresh({
                        url: "api/ecom/update",
                        method: "POST",
                        data: {
                            id: props.data?.data?.id,
                            themeId: value.themeId,
                            name: value.name,
                            description: value.description,
                            colorHex: value.colorHex,
                            logo: value.logo,
                            merchantId: value.merchantId,
                        },
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    dispatch(confirmModal({ modalType: ModalTypeEnum.AddNewEcomTheme }));
                    toast.success(t(StringResources.pages.employees.create.successMsg).toString());
                } else {
                    await executeAxiosRequestWithRefresh({
                        url: "api/ecom/create",
                        method: "POST",
                        data: {
                            themeId: value.themeId,
                            name: value.name,
                            description: value.description,
                            colorHex: value.colorHex,
                            logo: value.logo,
                            merchantId: activeMerchant?.id,
                        },
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    dispatch(confirmModal({ modalType: ModalTypeEnum.AddNewEcomTheme }));
                    toast.success(t(StringResources.pages.employees.create.successMsg).toString());
                }
            } catch (error: any) {
                toast.error(t(StringResources.pages.terminals.error.submitError).toString());
            }
        },
    });

    const copyToClipboard = async () => {
        try {
            navigator.clipboard.writeText(formValidation.values["themeId"]);
            toast.success(t(StringResources.pages.ecomThemes.edit.copyToClipboard).toString());
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleColorChange = async (color: string) => {
        await formValidation.setFieldValue("colorHex", color);
    };

    const handleLogoUpload = async (file: File) => {
        await formValidation.setFieldValue("logo", file);
    };

    const handleLogoRemove = async () => {
        await formValidation.setFieldValue("logo", null);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.AddNewEcomTheme }));
    };

    const onOkClick = async () => {
        await formValidation.handleSubmit();
    };

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>
                        {!props.data.data
                            ? `${t(StringResources.pages.ecomThemes.create.title)}`
                            : `${t(StringResources.pages.ecomThemes.edit.editTheme)}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-new-ecom-theme__modal-body">
                    <Form.Group className="add-new-ecom-theme__form-row">
                        <BaseInput
                            type={"text"}
                            name={"themeId"}
                            autocomplete={"off"}
                            label={`${t(StringResources.pages.ecomThemes.create.themeId)}`}
                            value={formValidation.values["themeId"]}
                            invalid={formValidation.touched["themeId"] && formValidation.errors["themeId"]}
                            onBlur={formValidation.handleBlur}
                            onChange={formValidation.handleChange}
                            disabled={true}
                        />

                        <button
                            type="button"
                            onClick={copyToClipboard}
                            className="add-new-ecom-theme__clipboard-button"
                        >
                            <CopyToClipboardIcon />
                        </button>
                    </Form.Group>

                    <div className="add-new-ecom-theme__general-theme-info">
                        <p className="add-new-ecom-theme__general-theme-info-title">{`${t(
                            StringResources.pages.ecomThemes.create.generalThemeInfo
                        )}`}</p>
                    </div>

                    <Form.Group className="add-new-ecom-theme__form-row">
                        <BaseInput
                            type={"text"}
                            name={"name"}
                            autocomplete={"off"}
                            label={`${t(StringResources.pages.ecomThemes.create.name)}`}
                            value={formValidation.values["name"]}
                            invalid={formValidation.touched["name"] && formValidation.errors["name"]}
                            onBlur={formValidation.handleBlur}
                            onChange={formValidation.handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="add-new-ecom-theme__form-row">
                        <BaseInput
                            type={"text"}
                            name={"description"}
                            autocomplete={"off"}
                            label={`${t(StringResources.pages.ecomThemes.create.description)}`}
                            value={formValidation.values["description"]}
                            invalid={formValidation.touched["description"] && formValidation.errors["description"]}
                            onBlur={formValidation.handleBlur}
                            onChange={formValidation.handleChange}
                        />
                    </Form.Group>

                    <div className="add-new-ecom-theme__design">
                        <p className="add-new-ecom-theme__design-title">{`${t(
                            StringResources.pages.ecomThemes.create.design
                        )}`}</p>
                    </div>

                    <Form.Group className="add-new-ecom-theme__form-row">
                        <ColorPicker colorHex={props.data?.data?.colorHex ?? ""} onColorChange={handleColorChange} />
                    </Form.Group>

                    <div className="add-new-ecom-theme__logo">
                        <p className="add-new-ecom-theme__logo-title">{`${t(
                            StringResources.pages.ecomThemes.create.logo
                        )}`}</p>
                    </div>

                    <Form.Group className="add-new-ecom-theme__form-row">
                        <div>
                            <LogoUploader
                                onLogoUpload={handleLogoUpload}
                                onLogoRemove={handleLogoRemove}
                                maxFileSize={50}
                                maxWidth={280}
                                maxHeigth={64}
                                uploadedLogo={fetchedLogo ?? undefined}
                            />
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.modal.close)}`}
                        styleType="line"
                    />

                    <BaseButton
                        handleClick={onOkClick}
                        text={
                            !props.data.data
                                ? `${t(StringResources.pages.ecomThemes.create.create)}`
                                : `${t(StringResources.pages.ecomThemes.edit.save)}`
                        }
                        styleType="solid"
                        disabled={!(formValidation.isValid && formValidation.dirty && !formValidation.isValidating)}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default AddNewEcomThemeModal;

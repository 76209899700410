export enum PageTypeEnum {
    None = "none",
    Agreements = "agreements",
    Authorizations = "authorizations",
    Batches = "batches",
    DailyBalance = "dailyBalance",
    ElectronicDocuments = "electronicDocuments",
    Payments = "payments",
    Settlements = "settlements",
    Transactions = "transactions",
    Terminals = "terminals",
    Employees = "employees",
    Dashboard = "dashboard",
    NotFound = "notFound",
    DisputeNew = "disputeNew",
    Overview = "overview",
    Disputes = "disputes",
    PayByLink = "payByLink",
    EcomThemes = "ecomThemes",
}

export enum ReportType {
    None = "none",
    Dashboard = "dashboard",
    NotFound = "notFound",
    Agreements = "agreements",
    Authorizations = "authorizations",
    Batches = "batches",
    DailyBalance = "dailyBalance",
    ElectronicDocuments = "electronicDocuments",
    Payments = "payments",
    Settlements = "settlements",
    Transactions = "transactions",
    Terminals = "terminals",
    Employees = "employees",
    DisputeNew = "disputeNew",
    Overview = "overview",
    Disputes = "disputes",
    PayByLink = "payByLink",
    PayByLinkTransactions = "payByLinkTransactions",
    EcomThemes = "ecomThemes",
}

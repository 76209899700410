import BaseInputWrapper from "components/baseInputWrapper/baseInputWrapper";
import { useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import calendar from "assets/svgs/calendar.svg";
import i18n from "utils/language/languageClient";
import "react-datepicker/dist/react-datepicker.css";
import "./baseDatepicker.scss";
import { SupportedLanguageEnum } from "utils/enums";
import is from "date-fns/locale/is";
registerLocale("is", is.is);

interface BaseDatepickerProps {
    label?: string;
    disabled?: boolean;
    onDateChange: (newDate?: Date) => void;
    value?: Date;
    isClearable?: boolean;
    minValue?: string;
    info?: string | boolean | undefined;
}

const BaseDatepicker: React.FC<BaseDatepickerProps> = ({
    disabled,
    label,
    value,
    onDateChange,
    isClearable = false,
    minValue,
    info = false,
}) => {
    const datePickerReference = useRef<any>(null);
    const minDate = minValue ? minValue : "01/01/2000";

    // https://www.npmjs.com/package/react-datepicker#localization
    const language = i18n.resolvedLanguage;

    const scrollToSelectedDate = () => {
        const datepickerDropdown = document.querySelector(".react-datepicker__month-year-dropdown");
        if (datepickerDropdown) {
            const scrollTo = datepickerDropdown.querySelector('[aria-selected="true"]');
            datepickerDropdown.scroll(0, scrollTo!.getBoundingClientRect().y - 280);
        }
    };

    const handleFocus = (e: any) => {
        const menuParentEl = e.target.closest(".special-menus.menu-open");
        if (menuParentEl) {
            menuParentEl.classList.add("is-datepicker-active");
        }

        if (datePickerReference?.current?.state.open) {
            scrollToSelectedDate();
        }
    };

    const handleBlur = (e: any) => {
        const menuParentEl = e.target.closest(".special-menus.menu-open");
        if (menuParentEl) {
            menuParentEl.classList.remove("is-datepicker-active");
        }
    };

    const dateChangeHandler = (date: Date | null) => {
        onDateChange(date || undefined);
    };

    return (
        <BaseInputWrapper label={label} value={value?.toString()} info={info}>
            <label className="datepicker-label-wrapper">
                <DatePicker
                    locale={language === SupportedLanguageEnum[SupportedLanguageEnum.Icelandic] ? "is" : "en"}
                    className={info ? "form-control info" : "form-control"}
                    showMonthYearDropdown
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    scrollableMonthYearDropdown
                    dateFormat="dd.MM.yyyy"
                    disabled={disabled}
                    ref={datePickerReference}
                    calendarStartDay={1}
                    formatWeekDay={nameOfDay => nameOfDay.substring(0, 1)}
                    minDate={new Date(minDate)}
                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                    selected={value}
                    onChange={dateChangeHandler}
                    isClearable={isClearable}
                />
                <img height="16" width="16" className="form-icon" src={calendar} />
            </label>
        </BaseInputWrapper>
    );
};
export default BaseDatepicker;

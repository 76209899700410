import { AsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import TableCellCurrency from "components/tableCellComponents/tableCellCurrency/tableCellCurrency";
import TableCellDate from "components/tableCellComponents/tableCellDate/tableCellDate";
import { ExportType, PermissionType, StatementNotificationType, SupportedLanguageEnum } from "./enums";
import i18n from "./language/languageClient";
import { ILookupResponse } from "./models";
import { IPortalRouteElement, PortalRouteElements } from "./routing/pathsAndElements";
import { nameof } from "ts-simple-nameof";
import { AxiosResponseHeaders } from "axios";
import { Currency } from "./formatter";
import { errorCodeReasons } from "pages/newDisputes/api/disputeReasonCodeMap";
import { DateTime } from "luxon";
import { PATHS } from "./routing/paths";

export async function executeContainerThunkDispatch<ReturnType, ArgumentType>(
    containerDispatch: ThunkDispatch<any, any, any>,
    thunkAction: AsyncThunk<ReturnType, ArgumentType, {}>,
    args: ArgumentType
) {
    let isValid = false;
    let error = undefined;
    let data = {} as ReturnType;

    try {
        data = await containerDispatch(thunkAction(args)).unwrap();
        isValid = true;
    } catch (exception) {
        error = exception;
    }

    return { data, isValid, error };
}

export const isProduction = process.env.NODE_ENV === "production";

export const devConsoleLog = (param1?: any, param2?: any) => {
    //process.env.NODE_ENV != "production" && console.log(param1, param2);
};

export const devConsoleError = (param1?: any, param2?: any) => {
    process.env.NODE_ENV != "production" && console.error(param1, param2);
};

export const devConsoleAssert = (condition: boolean) => {
    process.env.NODE_ENV != "production" && console.assert(condition);
};

export const getLanguages = (): ILookupResponse<number>[] => {
    return [
        { value: 1, name: SupportedLanguageEnum[SupportedLanguageEnum.Icelandic] },
        { value: 2, name: SupportedLanguageEnum[SupportedLanguageEnum.English] },
    ];
};

export const getDropdownValue = (value: string, defaultValue: string) => {
    return value.length > 1 ? value : defaultValue;
};

export const getColumnWidth = (
    data: Array<any>,
    accessor: string,
    header: any,
    width?: number,
    smallPadding?: boolean
) => {
    if (width) {
        return width;
    }

    if (data.length) {
        const maxWidth = 600;
        const padding = smallPadding ? 15 : 35;
        const spacingDate = 5;
        const spacingString = smallPadding ? 7 : 9;
        const headerText = typeof header === "function" ? header() : header;

        const cell = data[0];
        const headerLength = headerText.length;
        const cellMaxLength = Math.max(...data.map(row => (`${row[accessor]}` || "").length));

        const columnLength = Math.max(...data.map(row => (`${row[accessor]}` || "").length), headerText.length);

        // If its header is longer than the cell, we just set width by length.
        if (columnLength == headerText.length) {
            if (smallPadding) {
                return Math.min(maxWidth, columnLength * 9 + 35);
            }

            return Math.min(maxWidth, columnLength * spacingString + padding);
        }

        // If its a number.
        if (typeof cell[accessor] === "number" || !isNaN(Number(cell[accessor]))) {
            return Math.min(maxWidth, columnLength * spacingString + padding);
        }

        // If its a date.
        if (!isNaN(Date.parse(cell[accessor]))) {
            return Math.min(maxWidth, columnLength * spacingDate + padding);
        }

        // Default width.
        return Math.min(maxWidth, columnLength * spacingString + padding);
    }
};

export function createUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function convertLocalToUTCDate(date: Date | null | undefined) {
    if (!date) {
        return undefined;
    }

    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

export function convertUTCToLocalDate(date: Date | null | undefined) {
    if (!date) {
        return undefined;
    }

    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

export const handleChangeLanguage = (lng: string) => {
    localStorage.setItem("lng", lng);
    i18n.changeLanguage(lng);
};

export interface ExportMetadata {
    filename: string;
    contentType: string;
}

export function getFileMetada(exportType: ExportType): ExportMetadata {
    switch (exportType) {
        case ExportType.CSV:
            return {
                filename: `${getDateForFileName()}.csv`,
                contentType: "text/csv",
            };
        case ExportType.XLSX:
            return {
                filename: `${getDateForFileName()}.xlsx`,
                contentType: "application/octet-stream",
            };
        case ExportType.PDF:
            return getDownloadPdfFileMetadata();
    }
}

export function getDownloadPdfFileMetadata(): ExportMetadata {
    return {
        filename: `${getDateForFileName()}.pdf`,
        contentType: "application/pdf",
    };
}

export function getDateForFileName() {
    const pad2 = (n: number) => {
        return n < 10 ? "0" + n : n;
    };

    const date = new Date();
    const filename =
        date.getFullYear().toString() +
        pad2(date.getMonth() + 1) +
        pad2(date.getDate()) +
        pad2(date.getHours()) +
        pad2(date.getMinutes()) +
        pad2(date.getSeconds());

    return filename;
}

export function getContentDispositionFilenameUtf8(headers: AxiosResponseHeaders): string {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;

    var disposition = headers["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
        var matches = utf8FilenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            return decodeURIComponent(matches[1].replace(/['"]/g, ""));
        }
    }

    throw new Error("Incorrect filename");
}

export function getFile(data: any, filename: string, type: string) {
    const blob = new Blob([data], { type: type });
    const file = new File([blob], filename, { type: type });
    return file;
}

export function saveFile(data: any, filename: string, type: string) {
    const file = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(file);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}

export function downloadBase64PdfFile(content: string, name: string) {
    var a = document.createElement("a");
    a.href = `data:application/pdf;base64,${content}`;
    a.download = name;
    a.click();
}

const isDate = (str: string): boolean => {
    const [y, M, d]: string[] = str.split(/[- : T Z]/);
    return y && Number(M) <= 12 && Number(d) <= 31 ? true : false;
};

export const customTableCell = (data: number | string, currency?: Currency): React.ReactNode => {
    switch (typeof data) {
        case "number":
            return TableCellCurrency({
                value: data,
                format: currency,
                options: { formatOptions: currency === "ISK" ? "Symbol" : "Code", specialRules: [] },
            });
        case "string":
            return isDate(data) ? TableCellDate({ value: data, format: "date" }) : data;
        default:
            data;
    }
};

export function formatCurrencyNumber(value: number, decimals: number = 2): string {
    return value.toLocaleString("de-DE", { maximumFractionDigits: decimals });
}

export function propOf<T extends Object>(selector: (obj: T) => any): keyof T {
    return nameof(selector) as keyof T;
}

export function getFrontendVersion(): string {
    return process.env.BUILD_NUMBER ? "1.0." + process.env.BUILD_NUMBER : "dev";
}

export const getDisputeReasonCodeValue = (value: string, isCategoryDisplay: boolean) => {
    var textValues = errorCodeReasons.filter(x => x.code === value);

    if (textValues.length <= 0) {
        var closeValues = errorCodeReasons.filter(x => value.includes(x.code));
        if (closeValues.length > 0) {
            return isCategoryDisplay ? closeValues[0].category : closeValues[0].description;
        }
    }

    if (textValues.length == 1) {
        return isCategoryDisplay ? textValues[0].category : textValues[0].description;
    }

    if (textValues.length > 1) {
        return isCategoryDisplay ? textValues[0].category : "Multiple Rapyd descriptions";
    }

    return undefined;
};

export function monthDiff(date: DateTime, dateTo: DateTime): number {
    return Math.abs(date.month - dateTo.month + 12 * (date.year - dateTo.year)) + 1;
}

export function kycLanguageDetect(
    languageString: string,
    iskText: string | undefined | null,
    engText: string | undefined | null
): string | undefined | null {
    const language = SupportedLanguageEnum[languageString as keyof typeof SupportedLanguageEnum];

    if (language === SupportedLanguageEnum.Icelandic) {
        return iskText;
    } else {
        if (engText) {
            return engText;
        }
        return iskText;
    }
}

export function getFirstRouteWithPermission(permissions: string[]): string {
    let route = PATHS.Portal.Dashboard;
    for (let index = 0; index < PortalRouteElements.length; index++) {
        const element = PortalRouteElements[index];

        const hasPermissions = permissions.some(x => x === PermissionType[element.permission]);
        if (hasPermissions) {
            route = element.path;
            break;
        }
    }

    // It should never happen that we don't have any route with permission on it.
    // We should have at least one route with permission.
    return route;
}

export type ValueOf<T> = T[keyof T];
